import TAILWIND_CONFIG from 'tailwind.config'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import { Icon } from '@libs-components/components/icon'
import { Text } from '@libs-components/components/text'

const COLORS = TAILWIND_CONFIG.theme.extend.colors
const RatingDesc = ({
  attributes: { copiesSold, showRating, ratingCount, averageRating },
  isHorizontal,
}: {
  attributes: ProductType.Data['attributes']
  isHorizontal?: boolean
}) => {
  const { t } = useTranslation()
  const hasRating = showRating && !!ratingCount && !!averageRating
  const shouldShowCopiesSold = (() => {
    if (!Number.isFinite(copiesSold)) return false
    const minimumCopiesSold = +t('crowdin:product-card.rating.min-copies-sold')
    if (Number.isFinite(minimumCopiesSold)) return copiesSold >= minimumCopiesSold
    return copiesSold
  })()
  if (!shouldShowCopiesSold && !hasRating) return null
  return (
    <div
      data-testid='product-card-rating-container'
      className={twMerge(
        'flex items-center justify-between',
        isHorizontal
          ? 'mobileUp:flex-col mobileUp:items-start mobileUp:justify-start mobileUp:gap-2'
          : 'min-h-[33px]',
      )}
    >
      {shouldShowCopiesSold && (
        <div data-testid='product-card-rating-description' className='flex items-center gap-1'>
          <Icon type='group' style={{ color: COLORS['grayscale-800'] }} />
          <Text variant={isHorizontal ? 'label_s_400' : 'body_s_400'}>{copiesSold}</Text>
        </div>
      )}
      <div
        data-testid='product-card-rating'
        className='flex flex-grow items-center justify-end gap-1'
      >
        <Icon type='star_filled' style={{ color: COLORS['yellow-500'] }} />
        <Text variant={isHorizontal ? 'label_s_400' : 'body_s_400'}>
          {hasRating
            ? `${averageRating.toFixed(1)} (${ratingCount})`
            : t('crowdin:feedback.rating.collecting')}
        </Text>
      </div>
    </div>
  )
}

export default RatingDesc
